async function loadJsonFile(file) {
    const response = await fetch(file)
    if (response.status === 200) {
        const json = await response.json()
        return json;
    } else {
        // console.error('Error loading products:', response.status)
        console.log('File '+file+' not found');
        return null;
    }
}

const defaultGallery = [
    {url: '201202PoppedSoccerball.JPG', title: 'Soccer ball, microwaved'},
    {url: '201703OceanStudio.JPG', title: 'Ocean Studio'},
    {url: '201703PRIVATE.JPG', title: 'Private!'},
    {url: '201809FoggySunset.JPG', title: 'Foggy Sunset'},
    {url: '201809GuardBird-2.JPG', title: 'Guard Bird'},
    {url: '201810AlwaysThere.JPG', title: 'Always There'},
    {url: '201810CirqueDePelican.JPG', title: 'Cirque-De-Pelican'},
    {url: '201810FantasticBeasts.JPG', title: 'Fantastic Beasts'},
    {url: '201810MorningLight.JPG', title: 'Morning Light'},
    {url: '201810Sunset.JPG', title: 'Sunset'},
    {url: '201810SurrealSunset.JPG', title: 'Surreal Sunset'},
    {url: '201812PersonalMagritte.JPG', title: 'Personal Magritte'},
    {url: '201901FistSunset2019-4.JPG', title: 'Fist Sunset, 2019'},
    {url: '201901FitzgeraldFog-2.JPG', title: 'Fitzgerald Fog'},
    {url: '201901Homecoming.JPG', title: 'Homecoming'},
    {url: '201901LowTide-8.JPG', title: 'Low Tide'},
    {url: '201901LowTide-25.JPG', title: 'Low Tide'},
    {url: '201901LowTide-34.JPG', title: 'Low Tide'},
    {url: '201901LowTide-36.JPG', title: 'Low Tide'},
    {url: '201901SimplyRidiculous.JPG', title: 'Simply Ridiculous'},
    {url: '201901Sunset.JPG', title: 'Sunset'},
    {url: '201901ThinRedLine.JPG', title: 'Thin Red Line'},
    {url: '201902RunForCover.JPG', title: 'Run For Cover'},
    {url: '201903FoggyFitzgerald.JPG', title: 'Foggy Fitzgerald'},
    {url: '201903PoorPlanning.JPG', title: 'Poor Planning'},
    {url: '201906Sunset.JPG', title: 'Sunset'},
    {url: '201907EnchantedForest.JPG', title: 'Enchanted Forest'},
    {url: '201907EnchantedForest-2.JPG', title: 'Enchanted Forest'},
    {url: '201907MossBeachRanch-6.JPG', title: 'Moss Beach Ranch'},
    {url: '201907Sunset.JPG', title: 'Sunset'},
    {url: '201909BlahBlahBlah.JPG', title: 'Blah Blah Blah'},
    {url: '201909GoIntoTheLight.JPG', title: 'Go Into The Light!'},
    {url: '201909HMBYC.JPG', title: 'HMBYC'},
    {url: '201909OffOffOff.JPG', title: 'Off! Off! Off!'},
    {url: '201910EveningCommute.JPG', title: 'Evening Commute'},
    {url: '201911Bliss.JPG', title: 'Bliss'},
    {url: '202001EnchantedForest.JPG', title: 'Enchanted Forest'},
    {url: '202001EnchantedForest-2.JPG', title: 'Enchanted Forest'},
    {url: '202001Monochrome.JPG', title: 'Monochrome'},
    {url: '202001MorningMist.JPG', title: 'Morning Mist'},
    {url: '202002RestStop.JPG', title: 'Rest Stop'},
    {url: '202004Conversation.JPG', title: 'Conversation'},
    {url: '202004Sunset.JPG', title: 'Sunset'},
    {url: '202005Wanderlust.JPG', title: 'Wanderlust'},
    {url: '202008Storm.JPG', title: 'Storm'},
    {url: '202011CypressForest.JPG', title: 'Cypress Forest'},
    {url: '202011MossBeachRanch.JPG', title: 'Moss Beach Ranch'},
    {url: '202101Sunset.JPG', title: 'Sunset'},
    {url: '202103Sunset.JPG', title: 'Sunset'},
    {url: '202105Cliffs.JPG', title: 'Cliffs'},
    {url: '202105Fog.JPG', title: 'Fog'},
    {url: '202105GoofyGopher.JPG', title: 'Goofy Gopher'},
    {url: '202105Goosenecks.JPG', title: 'Goose neck barnacles'},
    {url: '202105LowestTideOfYear.JPG', title: 'Lowest Tide of the Year'},
    {url: '202105WhaleBone.JPG', title: 'Whale Bone'},
    // Add more images as needed
];

const defaultSlideshow = [
    {url: "/images/slideshow/_Y3A7576_5_4.JPG", "title": "October Sunset", captionClass: "text-light", navClass: ''},
    {url: "/images/slideshow/_76A0052.JPG", "title": "Frenzy", captionClass: "text-light"},
    {url: "/images/slideshow/_76A3390.JPG", "title": "Solitude", captionClass: "text-dark"},
    {url: "/images/slideshow/_76A5755_4_3.JPG", "title": "Was it something they said?", captionClass: "text-dark"},
    {url: "/images/slideshow/_76A8171_70_69.JPG", "title": "Thin Red Line", captionClass: "text-light"},
    {url: "/images/slideshow/_76A8279_8_7.JPG", "title": "Homecoming", captionClass: "text-light"},
    {url: "/images/slideshow/_Y3A3747_6_5.JPG", "title": "A Pillar Point morning", captionClass: "text-light"},
];

export default {
    namespaced: true,
    state: {
        gallery: [],
        slideshow: []
    },
    getters: {
        gallery: (state) => state.gallery,
        slideshow: (state) => state.slideshow
    },
    mutations: {
        gallery(state, items) {
            state.gallery = items
        },
        slideshow(state, items) {
            state.slideshow = items
        }
    },
    actions: {
        async loadSlideshow(context) {
            try {
                const json = await loadJsonFile('/images/slideshow/slideshow.json');
                // console.log('json: ' + json)
                if (json) {
                    context.commit('slideshow', json);
                } else {
                    console.log('Slideshow not found');
                    context.commit('slideshow', defaultSlideshow);
                }
            } catch (error) {
                console.error('Error loading slideshow:', error)
                // throw error
            }
        },
        async loadGallery(context) {
            try {
                const json = await loadJsonFile('/images/gallery/gallery.json');
                if (json) {
                    context.commit('gallery', json);
                } else {
                    // console.error('Error loading products:', response.status)
                    console.log('Gallery not found');
                    context.commit('gallery', defaultGallery);
                }
            } catch (error) {
                console.error('Error loading gallery:', error)
                // throw error
            }
        }
    }
}