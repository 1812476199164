import axios from "axios";

function parseOrderDetail(order) {

    const orderId = order.OrderId;
    const sessionId = order.CheckoutSession;
    const created = order.Created;
    const updated = order.Updated;
    const status = order.Status;
    const receiptURL = order.ReceiptURL ? order.ReceiptURL : null;
    const username = order.Username ? order.Username : null;
    const shipAddress = order.ShipAddress ? order.ShipAddress : null;
    const shippingRateId = order.ShippingRate ? order.ShippingRate : null;
    const chargeId = order.Charge ? order.Charge : null



    //  todo: add shipping detail
    //  todo: add total
    //  todo: add estimated ship date

    // todo: mask sensitive details like username and address

    const orderDetail = {
        orderId,
        sessionId,
        created,
        updated,
        status,
        username,
        shipAddress,
        shippingRateId,
        chargeId,
        receiptURL,
        items: [],
        shippingOptions: []
    }

    for (const key in order.Items) {
        const orderItem = order.Items[key]
        // console.log('orderItem['+key+']: ' + JSON.stringify(orderItem, null, 2));
        orderDetail.items.push(orderItem)
    }

    for (const key in order.ShippingOptions) {
        const shippingOption = order.ShippingOptions[key]
        // console.log('orderItem['+key+']: ' + JSON.stringify(orderItem, null, 2));
        orderDetail.shippingOptions.push(shippingOption)
    }

    return orderDetail;
}

export default {
    namespaced: true,
    state: {
        orders: [],
        enableShopping: true
    },
    getters: {
        orders(state) {
            return state.orders;
        },
        enableShopping(state) {
            return state.enableShopping;
        },
    },
    mutations: {
        orders(state, orders) {
            state.orders = orders;
        }
    },
    actions: {
        async orderSearch(context, payload) {
            const orderId = encodeURIComponent(payload.orderId);
            const username = encodeURIComponent(payload.username);
            const postalCode = encodeURIComponent(payload.postalCode);

            // console.log('find order: ' + JSON.stringify(payload, null, 2));

            const orders = await context.getters.orders;

            let order = orders.find(order => order.orderId.toUpperCase() === orderId);
            if (!order) {
                //     not found. load from api
                const orderDetailsURL = `${process.env.VUE_APP_API_URL}/order?orderId=${orderId}&username=${username}&postalCode=${postalCode}`;
                // console.log('orderDetailsURL: ' + orderDetailsURL)
                const response = await axios.get(orderDetailsURL, {
                    headers: {
                        'x-api-key': process.env.VUE_APP_API_KEY
                    }
                });

                const o = response.data
                if (o && o.orderId) {
                    order = parseOrderDetail(o);
                    orders.push(order)
                    return order;
                }
            } else {
                // console.log('order already loaded')
                return order;
            }
        },
        async findOrder(context, payload) {

            const sessionId = payload.sessionId;
            // console.log('find order with sessionId ' + sessionId);

            const orders = await context.getters.orders;

            let order = orders.find(order => order.sessionId === sessionId);
            if (!order) {
                //     not found. load from api
                const orderDetailsURL = `${process.env.VUE_APP_API_URL}/order?sessionId=${sessionId}`;
                // console.log('orderDetailsURL: ' + orderDetailsURL)
                const response = await axios.get(orderDetailsURL, {
                    headers: {
                        'x-api-key': process.env.VUE_APP_API_KEY
                    }
                });
                // console.log('response: ' + JSON.stringify(response))

                const o = response.data
                if (o.CheckoutSession === sessionId) {
                    order = parseOrderDetail(o);
                    orders.push(order)
                    return order;
                } else {
                    // throw new Error('order not found for sessionId['+sessionId+']')
                    return null;
                }
                /*const o = response.data
                console.log('order['+sessionId+']: ' + JSON.stringify(o, null, 2));
                if (o.sessionId === sessionId) {
                    console.log('order found')
                    order = o;
                    orders.push(order)
                } else {
                    // throw new Error('order not found for sessionId['+sessionId+']')
                    return null;
                }*/
            // } else {
            //     console.log('order already loaded')
            }
            // console.log('return order...')
            return order;
        }
    }
}