<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light bg-body py-3 fixed-top">
    <div class="container">
      <router-link to="/" class="navbar-brand d-flex align-items-center">
        <span>
          MossBeachLife.com
        </span>
      </router-link>

      <button
              class="navbar-toggler"
              :class="visible ? '' : 'collapsed'"
              :aria-expanded="visible ? 'true' : 'false'"
              @click="visible = !visible">
        <span class="visually-hidden"></span>
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="navbarNav"
           class="collapse navbar-collapse flex-grow-0"
           :class="visible ? 'show' : ''"
      >
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name === 'home' ? 'active' : ''" :to="{ name: 'home' }" @click="visible = false">Home</router-link>
          </li>
          <li v-if="enableShopping" class="nav-item" >
            <!-- TODO: sub menu of different products -->
            <router-link class="nav-link" :class="$route.name === 'store' ? 'active' : ''" :to="{ name: 'store' }" @click="visible = false">Store</router-link>
          </li>
          <li v-if="enableShopping" class="nav-item" >
            <router-link class="nav-link" :class="$route.name === 'cart' ? 'active' : ''" :to="{ name: 'cart' }" @click="visible = false">Cart</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :class="$route.name === 'gallery' ? 'active' : ''" :to="{ name: 'gallery' }" @click="visible = false">Gallery</router-link>
          </li>

          <li class="nav-item" v-if="enableAbout">
            <router-link class="nav-link" :class="$route.name === 'about' ? 'active' : ''" aria-current="page" :to="{ name: 'about' }" @click="visible = false">About</router-link>
          </li>
          <li class="nav-item" v-if="enableContact">
            <router-link class="nav-link" :class="$route.name === 'contact' ? 'active' : ''" :to="{ name: 'contact' }" @click="visible = false">Contact</router-link>
          </li>
        </ul>

      </div>

      <div class="d-none d-md-block">
        <router-link v-if="enableShopping" to="/cart" class="nav-link" :class="$route.name === 'cart' ? 'active' : ''">
          <div v-if="cartItems">
            <cart-heart-icon :size="25" :fill-color="$route.name === 'cart' ? 'blue' : ''"/>
          </div>
          <div v-else>
            <cart-outline-icon :size="25" :fill-color="$route.name === 'cart' ? 'blue' : ''"/>
          </div>
        </router-link>
      </div>
    </div>
  </nav>
</template>
<script>
import CartOutlineIcon from 'vue-material-design-icons/CartOutline.vue';
import CartHeartIcon from 'vue-material-design-icons/CartHeart.vue';

// https://github.com/robcresswell/vue-material-design-icons
// https://pictogrammers.com/library/mdi/
export default {
  components: {
    CartOutlineIcon,
    CartHeartIcon
  },
  computed: {
    cartItems() {
      const cart = this.$store.getters["cart/cart"];
      return cart && cart.length > 0;
    },
    enableShopping() {
      return this.$store.getters["orders/enableShopping"];
    }
  },
  data() {
    return {
      enableContact: false,
      enableAbout: false,
      visible: false
    }
  },
  watch: {
    '$route.query': {
      handler(newQuery) {
        // Handle the new query parameters here
        if (newQuery.mode === 'enhanced') {
          this.enableContact = true;
        }
      },
      immediate: true
    }
  }
}
</script>