<template>
  <div class="d-flex flex-column h-100">
    <header>
      <nav-bar />
    </header>
    <main class="flex-grow-0 p-0 m-0">
      <router-view/>
    </main>
    <footer class="footer mt-auto ">
      <footer-bar/>
    </footer>
  </div>
</template>

<script>
import NewNav from "@/components/nav/NewNav.vue";
import FooterBar from "@/components/nav/FooterBar.vue";

// https://github.com/robcresswell/vue-material-design-icons
// https://pictogrammers.com/library/mdi/
export default {
  components: {
    NavBar: NewNav,
    FooterBar
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

main > .container {
  padding: 70px 15px 0;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
  }
}

.nav-item .nav-link.active {
  font-weight: bold;
}

.nav-item .nav-link.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: 0;
}
</style>
