import axios from "axios";

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default {
  namespaced: true,
  state: {
    cart: [],
    shipping: 'TBD',
  },
  getters: {
    cart: (state) => state.cart,
    shipping: (state) => state.shipping,
  },
  mutations: {
    cart(state, cart) {
      state.cart = cart
    },
    shipping(state, shipping) {
      state.shipping = shipping
    }
  },
  actions: {
    async calculateShipping(context, cart) {
      const payload = {
        items: []
      };

      cart.map((item) => {
        payload.items.push(item);
      });

      const shippingUrl = process.env.VUE_APP_API_URL + "/shipping";

      // get list of shipping options for current cart
      const response = (await axios.post(shippingUrl, payload, {
        headers: {
          'x-api-key': process.env.VUE_APP_API_KEY
        }
      }));

      // given options, calculate min and max
      let sMin = null;
      let sMax = null;
      response.data.forEach((rate) => {
        // console.log('shipping rate: ' + JSON.stringify(rate, null, 2));
        const d = rate.shipping_rate_data;
        const amount = parseInt(d.fixed_amount.amount);
        if (sMin === null || amount < sMin) {
          sMin = amount;
        }
        if (sMax === null || amount > sMax) {
          sMax = amount;
        }
      })

      if (sMin != null && sMax != null) {
        if (sMin !== sMax) {
          return `TBD (${formatter.format(sMin > 0 ? sMin / 100 : 0)} - ${formatter.format(sMax > 0 ? sMax / 100 : 0)})`;
        } else {
          return `TBD (${formatter.format(sMax > 0 ? sMax / 100 : 0)})`;
        }

      } else {
        return 'TBD';
      }
    },
    async emptyCart(context) {
      let cart = context.getters["cart"];
      cart.length = 0
      await context.commit('shipping', 'TBD');
    },
    async addToCartNew(context, cartItem) {
      // await context.dispatch("loadCart");
      let cart = context.getters["cart"];

      // console.log('cart: ' + JSON.stringify(cart));
      // console.log('cartItem to add: ' + JSON.stringify(cartItem));

      const product = cartItem.product;
      const qty = cartItem.qty;

      // console.log('adding productId to cart: ' + product.productId);


      // Find the item in the cart by productId
      const existingItem = cart.find((item) => {
        // console.log('item in cart: ' + JSON.stringify(item));
        const itemProduct = item.product;
        // console.log('item in cart product: ' + JSON.stringify(itemProduct));
        return itemProduct.productId === product.productId
      });

      if (existingItem) {

        // console.log('item already in cart: ' + JSON.stringify(existingItem))

        //  if qty is different - adjust cart qty to match
        if (existingItem.qty !== qty) {
          existingItem.qty = qty;

          const shipping = await context.dispatch('calculateShipping', cart)
          // console.log('addToCartNew(existing): shipping updated: ' + shipping)
          await context.commit('shipping', shipping);

          return 'updated'
        } else {
          // console.log('item already in cart and addQuantities match, so we ignore request')
          throw {
            errorType: 'AlreadyInCart',
            message: 'Item already in cart and addQuantities match',
            item: existingItem
          };
        }

        // If the item exists, update its quantity
        // existingItem.qty += cartItem.qty;
        //  todo: raise an error - ask user if they would like to increase quantity in cart
        // throw new Error('Item already in cart');
        /*throw {
          errorType: 'AlreadyInCart',
          message: 'Item already in cart',
          item: existingItem
        };*/
      } else {
        // If the item doesn't exist, add it to the cart
        // console.log('adding to cart: ' + JSON.stringify(cartItem));
        cart.push(cartItem);

        // recalculate shipping?
        const shipping = await context.dispatch('calculateShipping', cart)
        // console.log('addToCartNew(new item): shipping updated: ' + shipping)
        await context.commit('shipping', shipping);

        return 'added'
      }
    },
    async incrementQty(context, productId){
      // console.log('increment')
      let cart = context.getters["cart"];
      // Find the item in the cart by productId
      const existingItem = cart.find((item) => {
        // console.log('item in cart: ' + JSON.stringify(item));
        const itemProduct = item.product;
        // console.log('item in cart product: ' + JSON.stringify(itemProduct));
        return itemProduct.productId === productId
      });

      if (existingItem) {
        existingItem.qty++;
        // console.log('cartItem after increment: ' + JSON.stringify(existingItem));

        // recalculate shipping?
        const shipping = await context.dispatch('calculateShipping', cart)
        // console.log('incrementQty: shipping updated: ' + shipping)
        await context.commit('shipping', shipping);
      }


    },
    async decrementQty(context, productId){
      // console.log('decrement ' + productId)
      let cart = context.getters["cart"];
      // Find the item in the cart by productId
      const existingItem = cart.find((item) => {
        // console.log('item in cart: ' + JSON.stringify(item));
        const itemProduct = item.product;
        // console.log('item in cart product: ' + JSON.stringify(itemProduct));
        return itemProduct.productId === productId
      });

      if (existingItem) {
        existingItem.qty--;
        if (existingItem.qty <= 0) cart.splice(cart.indexOf(existingItem), 1);

        // recalculate shipping?
        const shipping = await context.dispatch('calculateShipping', cart)
        // console.log('decrementQty: shipping updated: ' + shipping)
        await context.commit('shipping', shipping);
        // console.log('cartItem after decrement: ' + JSON.stringify(existingItem));
      } else {
        console.error('Item not found in cart to decrement')
      }
    }
  }
}
