import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import "bootstrap/dist/css/bootstrap.min.css"
import "@/assets/global.scss"
import "bootstrap"

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')
